.video-content video {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;

}

.video-content {
    width: 100%;
    height: 100%;
    background-color: #000;
    border-radius: 3rem;
    overflow: hidden;
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    padding-bottom: 3rem;
}

.video-content video::-webkit-media-controls-panel {
    z-index: 1;
}