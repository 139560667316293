.btn {
    position: absolute;
    top: 100%;
    right: 17.5%;
    display: flex;
    transform: translateY(-50%);
    font-size: 1.1rem;
    backface-visibility: hidden;
    z-index: 2;
}

.btn:global(.pointer-down) {
    transform: translateY(-50%) scale(0.97, 0.97);
}

.icon {
    width: 3em;
    height: 3em;
    border-width: 6px;
    border-style: solid;
    margin-right: 0.4em;
}

.btn:global(.mouse-over) {
    filter: drop-shadow(0 0 0.25rem rgba(255, 255, 255, 0.5));
}

.icon path {
    stroke: #fff;
    stroke-width: 0.375rem;
}

.text {
    align-self: flex-end;
    margin-bottom: -0.25rem;
    font-weight: bold;
    letter-spacing: 1px;
    color: #fff;
}