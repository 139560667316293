.content-box {
    width: 140vh;
    max-width: 80vw;
    max-height: 65vh;

    border: 1px solid #fff;
    border-radius: 3rem;
    padding: 1.5rem 2rem 3rem 2rem;

    position: relative;
    color: #fff;

    min-height: 30vh;
    margin-bottom: 7.5vh;
}

.content-box:global(.small) {
    width: 100vh;
    max-width: 65vw;
    max-height: 62.5vh;
}

.content-box:global(.medium) {
    width: 120vh;
    max-width: 70vw;
    max-height: 65vh;
}

.content-box:global(.game),
.content-box:global(.big) {
    /* ratio x:y */
    width: 80vw;
    /* w */
    height: 45vw;
    /* w / x * y */
    max-height: 100vh;
    max-width: 142.222222vh;
    /* w / y * x */
    position: relative;
    padding: 0;
}

.content-box:global(.slideshow) {
    width: 120vh;
    max-width: 75vw;
    max-height: 75vh;
    padding: 0;
}

.content-box:global(.video) {
    width: 77.5vw;
    max-height: 65vw;
    padding: 0;
}

.content-box-border {
    position: absolute;
    top: -3px;
    bottom: -3px;
    width: 12%;
    border-width: 6px;
    border-style: solid;
    pointer-events: none;
    z-index: 2;
}

.content-box-border:global(.left) {
    left: -3px;
    border-top-left-radius: 3rem;
    border-bottom-left-radius: 3rem;
    border-right: none;
}

.content-box-border:global(.right) {
    right: -3px;
    border-top-right-radius: 3rem;
    border-bottom-right-radius: 3rem;
    border-left: none;
}