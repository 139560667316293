.topic-menu-item {
    height: 10rem;
    width: 10rem;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    padding: 1rem;
    position: relative;
    margin-right: 1.25rem;
    transition: background-color 0.2s;
}

.topic-menu-item:hover {
    background-color: rgba(0, 0, 0, 0.7);
}

.topic-menu-item-icon {
    height: 100%;
    width: auto;
}

.topic-menu-item-text {
    position: absolute;
    top: 100%;
    line-height: 1.25;
    width: 100%;
    left: 0;
    text-align: center;    
    padding: 0.5rem 1rem;
    font-weight: bold;
}