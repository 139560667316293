.loader {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #000;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}