@font-face {
    font-family: 'Siemens Sans';
    src: url(../../static/media/siemens-sans_normal-normal.b29beda0.eot);
    src: url(../../static/media/siemens-sans_normal-normal.b29beda0.eot?#iefix) format('embedded-opentype'), url(../../static/media/siemens-sans_normal-normal.55d4bad5.woff) format('woff'), url(../../static/media/siemens-sans_normal-normal.cd4716e2.ttf) format('truetype'), url(../../static/media/siemens-sans_normal-normal.f0a52053.svg) format('svg');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Siemens Sans';
    src: url(../../static/media/siemens-sans_normal-italic.89aef404.eot);
    src: url(../../static/media/siemens-sans_normal-italic.89aef404.eot?#iefix) format('embedded-opentype'), url(../../static/media/siemens-sans_normal-italic.1233b9c8.woff) format('woff'), url(../../static/media/siemens-sans_normal-italic.b480cf39.ttf) format('truetype'), url(../../static/media/siemens-sans_normal-italic.f3e9944e.svg) format('svg');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Siemens Sans';
    src: url(../../static/media/siemens-sans_bold-italic.508d4d2e.eot);
    src: url(../../static/media/siemens-sans_bold-italic.508d4d2e.eot?#iefix) format('embedded-opentype'), url(../../static/media/siemens-sans_bold-italic.05950c1e.woff) format('woff'), url(../../static/media/siemens-sans_bold-italic.0512a2e8.ttf) format('truetype'), url(../../static/media/siemens-sans_bold-italic.92cd1add.svg) format('svg');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Siemens Sans';
    src: url(../../static/media/siemens-sans_bold-normal.b37206f5.eot);
    src: url(../../static/media/siemens-sans_bold-normal.b37206f5.eot?#iefix) format('embedded-opentype'), url(../../static/media/siemens-sans_bold-normal.c033585a.woff) format('woff'), url(../../static/media/siemens-sans_bold-normal.9c799958.ttf) format('truetype'), url(../../static/media/siemens-sans_bold-normal.09ab82fa.svg) format('svg');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Siemens Slab';
    src: url(../../static/media/siemens-slab_normal-normal.29b71d4a.eot);
    src: url(../../static/media/siemens-slab_normal-normal.29b71d4a.eot?#iefix) format('embedded-opentype'), url(../../static/media/siemens-slab_normal-normal.4c700ecf.woff) format('woff'), url(../../static/media/siemens-slab_normal-normal.5e54e33b.ttf) format('truetype'), url(../../static/media/siemens-slab_normal-normal.0e503960.svg) format('svg');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Siemens Slab';
    src: url(../../static/media/siemens-slab_normal-italic.086313c7.eot);
    src: url(../../static/media/siemens-slab_normal-italic.086313c7.eot?#iefix) format('embedded-opentype'), url(../../static/media/siemens-slab_normal-italic.faf27b53.woff) format('woff'), url(../../static/media/siemens-slab_normal-italic.72d72ef5.ttf) format('truetype'), url(../../static/media/siemens-slab_normal-italic.0121db30.svg) format('svg');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Siemens Slab';
    src: url(../../static/media/siemens-slab_bold-italic.92b7f7b1.eot);
    src: url(../../static/media/siemens-slab_bold-italic.92b7f7b1.eot?#iefix) format('embedded-opentype'), url(../../static/media/siemens-slab_bold-italic.502d2689.woff) format('woff'), url(../../static/media/siemens-slab_bold-italic.87763226.ttf) format('truetype'), url(../../static/media/siemens-slab_bold-italic.19815bc9.svg) format('svg');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Siemens Slab';
    src: url(../../static/media/siemens-slab_bold-normal.5bfa734c.eot);
    src: url(../../static/media/siemens-slab_bold-normal.5bfa734c.eot?#iefix) format('embedded-opentype'), url(../../static/media/siemens-slab_bold-normal.559e6813.woff) format('woff'), url(../../static/media/siemens-slab_bold-normal.b36fa264.ttf) format('truetype'), url(../../static/media/siemens-slab_bold-normal.8cc099d7.svg) format('svg');
    font-weight: 700;
    font-style: normal;
}
.fade-enter {
    opacity: 0.01;
}

.fade-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
}

.fade-enter-done,
.fade-exit {
    opacity: 1;
}

.fade-exit-active {
    opacity: 0.01;
    transition: opacity 500ms ease-in;
}

.fade-exit-done {
    opacity: 0;
}

.slide-top-enter {
    transform: translateY(-150%);
}

.slide-top-enter-active {
    transform: translateY(0);
    transition: transform 400ms ease-in-out 5000ms;
}

.slide-top-exit {
    transform: translateY(0);
}

.slide-top-exit-active {
    transform: translateY(-150%);
    transition: transform 400ms ease-in-out;
}

.fade-animation-enter {
    display: block;
    opacity: 0.01;
}

.fade-animation-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in;
}


.fade-loading-enter {
    display: block;
    opacity: 0.01;
}

.fade-loading-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in;
}

.fade-loading-enter-done,
.fade-loading-exit {
    opacity: 1;
}

.fade-loading-exit-active {
    opacity: 0.01;
    transition: opacity 1000ms ease-out 500ms;
}

.fade-loading-exit-done {
    display: none;
    opacity: 0;
}


.pano-fade-enter {
    display: block;
    opacity: 0.01;
}

.pano-fade-enter-active {
    opacity: 1;
    transition: opacity 400ms ease-in 100ms;
}

.pano-fade-enter-done,
.pano-fade-exit {
    opacity: 1;
}

.pano-fade-exit-active {
    opacity: 0.01;
    transition: opacity 500ms ease-out 500ms;
}

.pano-fade-exit-done {
    display: none;
    opacity: 0;
}

@keyframes lazyFadeIn {
    0% {
        opacity: 0;
    }

    66% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes rotateCenter {
    0% {
        transform: translate(-50%, -50%) rotate(0deg);
    }

    100% {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}
.Button_button__2WbI_ {
    outline: none;
    background: none;
    border: none;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    padding: 0;
    margin: 0;
}

.Button_button__2WbI_:focus {
    outline: none;
}

.Button_button__2WbI_:not([disabled]):not(.Button_disabled__G_Ahi) {
    cursor: pointer;
    pointer-events: all;    
}
.Chapter_chapter-container__AJMC9 {
    width: 100%;
    height: 100%;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);

    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100000;
}

.Chapter_chapter-container-close-layer__2m900 {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.ContentBox_content-box__1YBEp {
    width: 140vh;
    max-width: 80vw;
    max-height: 65vh;

    border: 1px solid #fff;
    border-radius: 3rem;
    padding: 1.5rem 2rem 3rem 2rem;

    position: relative;
    color: #fff;

    min-height: 30vh;
    margin-bottom: 7.5vh;
}

.ContentBox_content-box__1YBEp.small {
    width: 100vh;
    max-width: 65vw;
    max-height: 62.5vh;
}

.ContentBox_content-box__1YBEp.medium {
    width: 120vh;
    max-width: 70vw;
    max-height: 65vh;
}

.ContentBox_content-box__1YBEp.game,
.ContentBox_content-box__1YBEp.big {
    /* ratio x:y */
    width: 80vw;
    /* w */
    height: 45vw;
    /* w / x * y */
    max-height: 100vh;
    max-width: 142.222222vh;
    /* w / y * x */
    position: relative;
    padding: 0;
}

.ContentBox_content-box__1YBEp.slideshow {
    width: 120vh;
    max-width: 75vw;
    max-height: 75vh;
    padding: 0;
}

.ContentBox_content-box__1YBEp.video {
    width: 77.5vw;
    max-height: 65vw;
    padding: 0;
}

.ContentBox_content-box-border__79XII {
    position: absolute;
    top: -3px;
    bottom: -3px;
    width: 12%;
    border-width: 6px;
    border-style: solid;
    pointer-events: none;
    z-index: 2;
}

.ContentBox_content-box-border__79XII.left {
    left: -3px;
    border-top-left-radius: 3rem;
    border-bottom-left-radius: 3rem;
    border-right: none;
}

.ContentBox_content-box-border__79XII.right {
    right: -3px;
    border-top-right-radius: 3rem;
    border-bottom-right-radius: 3rem;
    border-left: none;
}
.ContentBoxCloseButton_btn__13fHm {
    position: absolute;
    top: 100%;
    right: 17.5%;
    display: flex;
    transform: translateY(-50%);
    font-size: 1.1rem;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    z-index: 2;
}

.ContentBoxCloseButton_btn__13fHm.pointer-down {
    transform: translateY(-50%) scale(0.97, 0.97);
}

.ContentBoxCloseButton_icon__1L54g {
    width: 3em;
    height: 3em;
    border-width: 6px;
    border-style: solid;
    margin-right: 0.4em;
}

.ContentBoxCloseButton_btn__13fHm.mouse-over {
    filter: drop-shadow(0 0 0.25rem rgba(255, 255, 255, 0.5));
}

.ContentBoxCloseButton_icon__1L54g path {
    stroke: #fff;
    stroke-width: 0.375rem;
}

.ContentBoxCloseButton_text__1dBiT {
    align-self: flex-end;
    margin-bottom: -0.25rem;
    font-weight: bold;
    letter-spacing: 1px;
    color: #fff;
}
.GameContent_game-content__zvL9z {
    border-radius: 3rem;
    overflow: hidden;
    background-color: #000;
    -webkit-mask-image: -webkit-radial-gradient(white, black);
}

.GameContent_game-content__zvL9z {
    width: 100%;
    height: 100%;
    position: relative;
}

.GameContent_game-content__zvL9z iframe {
    border: none;
    outline: none;
    width: 1px;
    height: 1px;
    min-width: 100%;
    min-height: 100%;
    max-width: 100%;
    max-height: 100%;
}
.Spinner_spinner-wrapper__1JQWd {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Spinner_spinner__sHnZy {
    color: #fff;
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
    opacity: 1;
}

.Spinner_spinner__sHnZy {
    animation: lazyFadeIn 3s linear 1;
}

.Spinner_spinner__sHnZy div {
    transform-origin: 32px 32px;
    animation: fadeIn 1.2s linear infinite;
}

.Spinner_spinner__sHnZy div:after {
    content: " ";
    display: block;
    position: absolute;
    top: 3px;
    left: 29px;
    width: 5px;
    height: 14px;
    border-radius: 20%;
    background: #fff;
}

.Spinner_spinner__sHnZy div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
}

.Spinner_spinner__sHnZy div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
}

.Spinner_spinner__sHnZy div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
}

.Spinner_spinner__sHnZy div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
}

.Spinner_spinner__sHnZy div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
}

.Spinner_spinner__sHnZy div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
}

.Spinner_spinner__sHnZy div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
}

.Spinner_spinner__sHnZy div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
}

.Spinner_spinner__sHnZy div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
}

.Spinner_spinner__sHnZy div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
}

.Spinner_spinner__sHnZy div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
}

.Spinner_spinner__sHnZy div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
}
.SlideshowContent_slideshow-content__sCMpu {
    width: 100%;
    height: 100%;
    position: relative;
}

.SlideshowContent_slideshow-content__sCMpu .swiper-container {

    border-radius: 3rem;
    overflow: hidden;
}

.SlideshowContent_slideshow-content__sCMpu .swiper-slide {
    min-height: 30vh;
    background-color: rgba(255, 255, 255, 0.1);
}

.SlideshowContent_slideshow-content__sCMpu img {
    width: 100%;
    height: auto;    
}

.SlideshowContent_slideshow-indicator__nHmhO {
    position: absolute;
    top: 100%;
    margin-top: 0.25rem;
    font-size: 110%;
    font-weight: bold;
    left: 50%;
    transform: translateX(-50%);
}

.SlideshowContent_slideshow-indicator__nHmhO .SlideshowContent_slideshow-indicator-separator__c2Kg_ {
    padding: 0 0.5rem;
}

.SlideshowContent_slideshow-control__1_tdn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 25%;
    width: 7rem;    
    opacity: 1;
    transition: opacity 0.2s;
}

.SlideshowContent_slideshow-control__1_tdn.disabled {
    opacity: 0.25;
}

.SlideshowContent_slideshow-control__1_tdn.pointer-down {
    transform: translateY(-50%) scale(0.95, 0.95);
}

.SlideshowContent_slideshow-control__1_tdn.mouse-over {
    filter: drop-shadow(0 0 0.25rem rgba(255, 255, 255, 0.5));
}

.SlideshowContent_slideshow-control__1_tdn svg line {
    stroke: white;
    stroke-width: 4px;
    stroke-linecap: round;
}

.SlideshowContent_slideshow-control__1_tdn.prev {
    right: 100%;
    padding-right: 1.5rem;
}

.SlideshowContent_slideshow-control__1_tdn.next {
    left: 100%;
    padding-left: 1.5rem;
}
.VideoContent_video-content__1Dttn video {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;

}

.VideoContent_video-content__1Dttn {
    width: 100%;
    height: 100%;
    background-color: #000;
    border-radius: 3rem;
    overflow: hidden;
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    padding-bottom: 3rem;
}

.VideoContent_video-content__1Dttn video::-webkit-media-controls-panel {
    z-index: 1;
}
.App_krpano-wrapper__3V4-B {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
}

.App_krpano-wrapper__3V4-B {
    animation: fadeIn 2s;
}
.ChapterMenu_menu__1d5_I {
    position: absolute;
    left: 0;
    top: 0;
}
.ChapterMenuItem_item-wrapper__1wRPq {
    position: absolute;
    transform: translate(-50%, -50%);
}

.ChapterMenuItem_item__2H3TM {
    background-color: rgba(0, 0, 0, 0.65);
    width: 4.9rem;
    height: 4.9rem;
    border-radius: 50%;
    padding: 1rem;
    box-shadow: 0px 0px 16px rgba(255, 255, 255, 0.75);
    transition: box-shadow 0.2s, border-color 0.2s;
    border: 1px solid rgba(255, 255, 255, 0.2);
    
}

.ChapterMenuItem_item__2H3TM.has-text {
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.ChapterMenuItem_item__2H3TM.pointer-down {
    transform: scale(0.97, 0.97);
}

.ChapterMenuItem_item__2H3TM.has-text .ChapterMenuItem_icon__3h6aB {
    flex: 1 1;
    margin-top: auto;
    padding-bottom: 0.25rem;
    min-height: 1px;
    flex-direction: row;
    align-items: flex-end;
}


.ChapterMenuItem_item__2H3TM:hover {
    border-color: #fff;
    box-shadow: 0px 0px 16px rgba(255, 255, 255, 1), 0px 0px 16px rgba(255, 255, 255, 1);
}

.ChapterMenuItem_item__2H3TM .ChapterMenuItem_icon__3h6aB {
    width: 100%;
    height: 100%;    

    display: flex;
    flex-direction: column;
    filter: drop-shadow(0 0 0.15rem rgba(255, 255, 255, 1));
}

.ChapterMenuItem_item__2H3TM.has-text .ChapterMenuItem_icon__3h6aB > img.video {
    width: 75%;
}

.ChapterMenuItem_item__2H3TM.has-text .ChapterMenuItem_icon__3h6aB > img.game {
    width: 82.5%;
}

.ChapterMenuItem_item__2H3TM .ChapterMenuItem_icon__3h6aB > img {
    flex: none;
    max-height: 100%;
    max-width: 100%;
    margin-top: auto;
    margin-bottom: auto;
}

.ChapterMenuItem_item__2H3TM.has-text .ChapterMenuItem_icon__3h6aB > img {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0;
}

.ChapterMenuItem_item__2H3TM .ChapterMenuItem_text__HA6Fg {
    flex: none;
    color: #fff;
    height: 40%;
    font-weight: bold;
    text-align: center;
    font-size: 0.525rem;    
    max-width: 100%;
    display: block;
}

.ChapterMenuItem_item__2H3TM .ChapterMenuItem_note__1MXmo {
    color: #fff;
    position: absolute;
    top: 42%;
    right: 15%;
    font-size: 0.525rem;    
    font-weight: bold;
}
.HotspotRings_container__Ay3LW {
    position: absolute;
    width: 5rem;
    height: 5rem;
}

.HotspotRings_ring-1__-UezJ,
.HotspotRings_ring-2__2U-2h,
.HotspotRings_ring-3__1oEDr {
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    position: absolute;
    transform: translate(-50%, -50%);
}

.HotspotRings_ring-2__2U-2h,
.HotspotRings_ring-3__1oEDr {
    animation: rotateCenter 5s linear infinite;
}

.HotspotRings_ring-1__-UezJ {
    width: 110%;
}

.HotspotRings_ring-2__2U-2h {
    width: 117%;
}

.HotspotRings_ring-3__1oEDr {
    width: 130%;
}
.TopicHotspot_hotspot__3RaoP {
    background-color: rgba(255, 255, 0, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    transform: translateZ(0) scale(4);
}

.TopicHotspot_text__1DsaF {
    position: absolute;
    top: 4.25rem;
    left: 0;
    z-index: 2;
    color: #fff;
    text-shadow: 0px 2px 6px rgba(0, 0, 0, 0.5), 0px -2px 6px rgba(0, 0, 0, 0.2);
    transform: translateX(-50%);
    transform-origin: 50% 50%;
    font-size: 0.6rem;
    font-weight: bold;
    text-align: center; 
    max-width: 4.5rem;
   
    height: 3rem;
    min-width: 5rem;
    min-height: 3rem;

    display: block;
}

.TopicHotspot_text__1DsaF.transform3d {
    transform: translateX(-50%) matrix3d(1, 0, 0.00, 0, 0.00, 1, 0.00, -0.005, 0, 0, 1, 0, 0, 0, 0, 1);
}

.TopicHotspot_text__1DsaF > span {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;    
    transform: translate(-50%, -50%);
}

.TopicHotspot_menu__1SYYM {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
}

.TopicHotspot_icon__3w7jn {
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    left: 0;
    top: 0;
    transform: translate(-50%, -50%);
    position: absolute;
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    padding: 0.5rem;
    background-color: rgba(0, 0, 0, 0.65);
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 16px rgba(255, 255, 255, 1);
}

.TopicHotspot_icon__3w7jn img {
    max-width: 100%;
    max-height: 100%;
    width: 80%;
    height: auto;
}

.TopicHotspot_icon__3w7jn .TopicHotspot_icon-topic__3j47x {
    width: 80%;
    height: auto;
    filter: drop-shadow(0 0 0.15rem rgba(255, 255, 255, 1));
}

.TopicHotspot_icon__3w7jn img.anti-corruption {
    width: 90%;
}
.TopicHotspot_icon__3w7jn img.data-privacy {
    width: 82%;
    padding-left: 0.25rem;
    padding-top: 0.25rem;
}

.TopicHotspot_icon__3w7jn img.anti-money-laundering {
    width: 75%;
    padding-top: 0.3rem;
}

.TopicHotspot_icon__3w7jn:before {
    content: "";
    border-radius: 50rem;
    position: absolute;
    height: 10rem;

    top: 0;
    left: 0;
    width: 5rem;
    background-image: linear-gradient(to top, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.25), rgba(255, 255, 255, 0));
}
.LocationIndicator_indicator__Wp7cb {    
    height: 10rem;
    width: 10rem;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.5);
    /* overflow: visible; */
}

.LocationIndicator_outer-circle-slice__2W3wk {
    stroke: #ffffff;
    stroke-width: 1px;
}

.LocationIndicator_inner-circle-slice__2T_uT {
    fill: #ffffff;
}

.LocationIndicator_indicator__Wp7cb .LocationIndicator_inner-circle-position__3RWLs {    
    fill: rgba(255, 255, 255, 0.1);
    transition: d 0.3s;
}

.LocationIndicator_indicator__Wp7cb circle {
    stroke: #ffffff;
    stroke-width: 1px;
}

.LocationIndicator_indicator__Wp7cb path {
    transition: fill 0.3s;
}
.LanguageButton_language-button__Bh339 span {
    font-size: 0.9rem;
    color: #fff;
    font-weight: bold;
}

.LanguageButton_language-button__Bh339 {
    background-color: rgba(0, 0, 0, 0.4);
    transition: background-color 0.2s;
    transform: scale(1, 1);
    padding: 0.15rem 1rem;
    border-radius: 0.3rem;
    
}

.LanguageButton_language-button__Bh339.active {
    background-color: rgba(0, 0, 0, 0.7);
}

.LanguageButton_language-button__Bh339.pointer-down {
    transform: scale(0.97, 0.97);
}
.LanguageSelection_language-selection-circular__3oQaA {
    height: 10rem;
    width: 10rem;
    /* overflow: visible; */
}

.LanguageSelection_language-selection-label__2pak6 {
    position: absolute;
    top: 100%;
    line-height: 1.25;
    width: 100%;
    left: 0;
    text-align: center;
    padding: 0.5rem 1rem;
    font-weight: bold;
}

.LanguageSelection_language-selection-stacked__rvH2E {
    margin-left: 2rem;
    position: relative;
    min-width: 8.25rem;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    /* justify-items: center; */
}

.LanguageSelection_language-selection-stacked__rvH2E > button:not(:first-child) {
    margin-top: 0.25rem;
}
.LanguageSlice_language-slice__2lFtX text, .LanguageSlice_language-slice__2lFtX tspan {
    alignment-baseline: middle;
    dominant-baseline: middle;
    text-anchor: middle;
    fill: #fff;
    cursor: pointer;
}

.LanguageSlice_language-slice__2lFtX text {
    font-size: 1.25rem;
}

.LanguageSlice_language-slice__2lFtX path {
    fill: rgba(0, 0, 0, 0.4);
    transition: fill 0.2s;
}

.LanguageSlice_language-slice__2lFtX.active path {
    fill: rgba(0, 0, 0, 0.7);
}
.StaticUI_static-ui-container__1_UEm {
    position: absolute;
    height: 10rem;
    display: flex;
    align-items: flex-end;

    z-index: 99999;
}

.StaticUI_static-ui-container__1_UEm.top {
    top: 2rem;
    bottom: auto;
}

.StaticUI_static-ui-container__1_UEm.bottom {
    top: auto;
    bottom: 4rem;
}

.StaticUI_static-ui-container__1_UEm.left {
    left: 3rem;
}

.StaticUI_static-ui-container__1_UEm.right {
    right: 3rem;
}

.StaticUI_intro-button__2q5aA {
    padding: 1rem;
    position: absolute;
    opacity: 0;
    left: 0;
    top: 0;
    background-color: #ccc;
}

.StaticUI_idle-button__3XMBs {
    padding: 1rem;
    position: absolute;
    opacity: 0;
    right: 0;
    top: 0;
    background-color: #ccc;
}
.TopicMenu_topic-menu__2-2gE {
    height: 100%;
}
.TopicMenuItem_topic-menu-item__1crRg {
    height: 10rem;
    width: 10rem;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    padding: 1rem;
    position: relative;
    margin-right: 1.25rem;
    transition: background-color 0.2s;
}

.TopicMenuItem_topic-menu-item__1crRg:hover {
    background-color: rgba(0, 0, 0, 0.7);
}

.TopicMenuItem_topic-menu-item-icon__1PbLl {
    height: 100%;
    width: auto;
}

.TopicMenuItem_topic-menu-item-text__32fwS {
    position: absolute;
    top: 100%;
    line-height: 1.25;
    width: 100%;
    left: 0;
    text-align: center;    
    padding: 0.5rem 1rem;
    font-weight: bold;
}
.Loader_loader__27AF0 {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #000;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}
.Progress_progress__XdXWh {
    position: relative;
    min-width: 320px;
    max-width: 520px;
    padding: 1rem;
    z-index: 2;
    text-align: center;
}

.Progress_progress__XdXWh .Progress_progress-bar__3Umam {
    background-color: rgba(255, 255, 255, 0.25);
    width: 100%;
    height: 0.75rem;
    border-radius: 10rem;
    overflow: hidden;
}

.Progress_progress__XdXWh .Progress_progress-bar-inner__3KWpg {
    height: 100%;
    transition: width 0.2s;
    background-color: #fff;
    min-width: 1px;
}

.Progress_progress__XdXWh .Progress_progress-text__3Gl0_ {
    padding-top: 0.5rem;
    color: #fff;
}

.Progress_progress__XdXWh .Progress_progress-message__1weUa {
    padding-bottom: 1rem;
    color: #fff;
}
body {
    font-family: "Siemens Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body,
html {
    background-color: #000;
    width: 100%;
    height: 100%;
}

* {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-touch-callout: none;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

#root {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
}

#root > .content {
    width: 100%;
    height: 100%;
}

::-webkit-scrollbar {
    width: 6px;
    height: 6px
}

::-webkit-scrollbar-button {
    width: 0;
    height: 0
}

::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.5);
    border: 0 #fff;
    border-radius: 50px
}

::-webkit-scrollbar-thumb:hover {
    background: rgba(255, 255, 255, 0.9);
}

::-webkit-scrollbar-thumb:active {
    background: rgba(255, 255, 255, 1);
}

::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.1);
    transition: background-color .2s;
    border: 0 #fff;
    border-radius: 50px;
}

::-webkit-scrollbar-track:active,
::-webkit-scrollbar-track:hover {
    background: rgba(255, 255, 255, 0.15);
}

::-webkit-scrollbar-corner {
    background: transparent
}
