.indicator {    
    height: 10rem;
    width: 10rem;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.5);
    /* overflow: visible; */
}

.outer-circle-slice {
    stroke: #ffffff;
    stroke-width: 1px;
}

.inner-circle-slice {
    fill: #ffffff;
}

.indicator .inner-circle-position {    
    fill: rgba(255, 255, 255, 0.1);
    transition: d 0.3s;
}

.indicator circle {
    stroke: #ffffff;
    stroke-width: 1px;
}

.indicator path {
    transition: fill 0.3s;
}