.game-content {
    border-radius: 3rem;
    overflow: hidden;
    background-color: #000;
    -webkit-mask-image: -webkit-radial-gradient(white, black);
}

.game-content {
    width: 100%;
    height: 100%;
    position: relative;
}

.game-content iframe {
    border: none;
    outline: none;
    width: 1px;
    height: 1px;
    min-width: 100%;
    min-height: 100%;
    max-width: 100%;
    max-height: 100%;
}