@font-face {
    font-family: 'Siemens Sans';
    src: url(../fonts/siemens-sans/siemens-sans_normal-normal.eot);
    src: url(../fonts/siemens-sans/siemens-sans_normal-normal.eot?#iefix) format('embedded-opentype'), url(../fonts/siemens-sans/siemens-sans_normal-normal.woff) format('woff'), url(../fonts/siemens-sans/siemens-sans_normal-normal.ttf) format('truetype'), url(../fonts/siemens-sans/siemens-sans_normal-normal.svg) format('svg');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Siemens Sans';
    src: url(../fonts/siemens-sans/siemens-sans_normal-italic.eot);
    src: url(../fonts/siemens-sans/siemens-sans_normal-italic.eot?#iefix) format('embedded-opentype'), url(../fonts/siemens-sans/siemens-sans_normal-italic.woff) format('woff'), url(../fonts/siemens-sans/siemens-sans_normal-italic.ttf) format('truetype'), url(../fonts/siemens-sans/siemens-sans_normal-italic.svg) format('svg');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Siemens Sans';
    src: url(../fonts/siemens-sans/siemens-sans_bold-italic.eot);
    src: url(../fonts/siemens-sans/siemens-sans_bold-italic.eot?#iefix) format('embedded-opentype'), url(../fonts/siemens-sans/siemens-sans_bold-italic.woff) format('woff'), url(../fonts/siemens-sans/siemens-sans_bold-italic.ttf) format('truetype'), url(../fonts/siemens-sans/siemens-sans_bold-italic.svg) format('svg');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Siemens Sans';
    src: url(../fonts/siemens-sans/siemens-sans_bold-normal.eot);
    src: url(../fonts/siemens-sans/siemens-sans_bold-normal.eot?#iefix) format('embedded-opentype'), url(../fonts/siemens-sans/siemens-sans_bold-normal.woff) format('woff'), url(../fonts/siemens-sans/siemens-sans_bold-normal.ttf) format('truetype'), url(../fonts/siemens-sans/siemens-sans_bold-normal.svg) format('svg');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Siemens Slab';
    src: url(../fonts/siemens-slab/siemens-slab_normal-normal.eot);
    src: url(../fonts/siemens-slab/siemens-slab_normal-normal.eot?#iefix) format('embedded-opentype'), url(../fonts/siemens-slab/siemens-slab_normal-normal.woff) format('woff'), url(../fonts/siemens-slab/siemens-slab_normal-normal.ttf) format('truetype'), url(../fonts/siemens-slab/siemens-slab_normal-normal.svg) format('svg');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Siemens Slab';
    src: url(../fonts/siemens-slab/siemens-slab_normal-italic.eot);
    src: url(../fonts/siemens-slab/siemens-slab_normal-italic.eot?#iefix) format('embedded-opentype'), url(../fonts/siemens-slab/siemens-slab_normal-italic.woff) format('woff'), url(../fonts/siemens-slab/siemens-slab_normal-italic.ttf) format('truetype'), url(../fonts/siemens-slab/siemens-slab_normal-italic.svg) format('svg');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Siemens Slab';
    src: url(../fonts/siemens-slab/siemens-slab_bold-italic.eot);
    src: url(../fonts/siemens-slab/siemens-slab_bold-italic.eot?#iefix) format('embedded-opentype'), url(../fonts/siemens-slab/siemens-slab_bold-italic.woff) format('woff'), url(../fonts/siemens-slab/siemens-slab_bold-italic.ttf) format('truetype'), url(../fonts/siemens-slab/siemens-slab_bold-italic.svg) format('svg');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Siemens Slab';
    src: url(../fonts/siemens-slab/siemens-slab_bold-normal.eot);
    src: url(../fonts/siemens-slab/siemens-slab_bold-normal.eot?#iefix) format('embedded-opentype'), url(../fonts/siemens-slab/siemens-slab_bold-normal.woff) format('woff'), url(../fonts/siemens-slab/siemens-slab_bold-normal.ttf) format('truetype'), url(../fonts/siemens-slab/siemens-slab_bold-normal.svg) format('svg');
    font-weight: 700;
    font-style: normal;
}