.container {
    position: absolute;
    width: 5rem;
    height: 5rem;
}

.ring-1,
.ring-2,
.ring-3 {
    backface-visibility: hidden;
    position: absolute;
    transform: translate(-50%, -50%);
}

.ring-2:global,
.ring-3:global {
    animation: rotateCenter 5s linear infinite;
}

.ring-1 {
    width: 110%;
}

.ring-2 {
    width: 117%;
}

.ring-3 {
    width: 130%;
}