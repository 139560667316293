body {
    font-family: "Siemens Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body,
html {
    background-color: #000;
    width: 100%;
    height: 100%;
}

* {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-touch-callout: none;
    user-select: none;
}

#root {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
}

#root > .content {
    width: 100%;
    height: 100%;
}

::-webkit-scrollbar {
    width: 6px;
    height: 6px
}

::-webkit-scrollbar-button {
    width: 0;
    height: 0
}

::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.5);
    border: 0 #fff;
    border-radius: 50px
}

::-webkit-scrollbar-thumb:hover {
    background: rgba(255, 255, 255, 0.9);
}

::-webkit-scrollbar-thumb:active {
    background: rgba(255, 255, 255, 1);
}

::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.1);
    -webkit-transition: background-color .2s;
    transition: background-color .2s;
    border: 0 #fff;
    border-radius: 50px;
}

::-webkit-scrollbar-track:active,
::-webkit-scrollbar-track:hover {
    background: rgba(255, 255, 255, 0.15);
}

::-webkit-scrollbar-corner {
    background: transparent
}