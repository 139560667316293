.hotspot {
    background-color: rgba(255, 255, 0, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    transform: translateZ(0) scale(4);
}

.text {
    position: absolute;
    top: 4.25rem;
    left: 0;
    z-index: 2;
    color: #fff;
    text-shadow: 0px 2px 6px rgba(0, 0, 0, 0.5), 0px -2px 6px rgba(0, 0, 0, 0.2);
    transform: translateX(-50%);
    transform-origin: 50% 50%;
    font-size: 0.6rem;
    font-weight: bold;
    text-align: center; 
    max-width: 4.5rem;
   
    height: 3rem;
    min-width: 5rem;
    min-height: 3rem;

    display: block;
}

.text:global(.transform3d) {
    transform: translateX(-50%) matrix3d(1, 0, 0.00, 0, 0.00, 1, 0.00, -0.005, 0, 0, 1, 0, 0, 0, 0, 1);
}

.text > span {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;    
    transform: translate(-50%, -50%);
}

.menu {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
}

.icon {
    backface-visibility: hidden;
    left: 0;
    top: 0;
    transform: translate(-50%, -50%);
    position: absolute;
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    padding: 0.5rem;
    background-color: rgba(0, 0, 0, 0.65);
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 16px rgba(255, 255, 255, 1);
}

.icon img {
    max-width: 100%;
    max-height: 100%;
    width: 80%;
    height: auto;
}

.icon .icon-topic {
    width: 80%;
    height: auto;
    filter: drop-shadow(0 0 0.15rem rgba(255, 255, 255, 1));
}

.icon img:global(.anti-corruption) {
    width: 90%;
}
.icon img:global(.data-privacy) {
    width: 82%;
    padding-left: 0.25rem;
    padding-top: 0.25rem;
}

.icon img:global(.anti-money-laundering) {
    width: 75%;
    padding-top: 0.3rem;
}

.icon:before {
    content: "";
    border-radius: 50rem;
    position: absolute;
    height: 10rem;

    top: 0;
    left: 0;
    width: 5rem;
    background-image: linear-gradient(to top, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.25), rgba(255, 255, 255, 0));
}