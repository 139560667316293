.item-wrapper {
    position: absolute;
    transform: translate(-50%, -50%);
}

.item {
    background-color: rgba(0, 0, 0, 0.65);
    width: 4.9rem;
    height: 4.9rem;
    border-radius: 50%;
    padding: 1rem;
    box-shadow: 0px 0px 16px rgba(255, 255, 255, 0.75);
    transition: box-shadow 0.2s, border-color 0.2s;
    border: 1px solid rgba(255, 255, 255, 0.2);
    
}

.item:global(.has-text) {
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.item:global(.pointer-down) {
    transform: scale(0.97, 0.97);
}

.item:global(.has-text) .icon {
    flex: 1 1 0;
    margin-top: auto;
    padding-bottom: 0.25rem;
    min-height: 1px;
    flex-direction: row;
    align-items: flex-end;
}


.item:hover {
    border-color: #fff;
    box-shadow: 0px 0px 16px rgba(255, 255, 255, 1), 0px 0px 16px rgba(255, 255, 255, 1);
}

.item .icon {
    width: 100%;
    height: 100%;    

    display: flex;
    flex-direction: column;
    filter: drop-shadow(0 0 0.15rem rgba(255, 255, 255, 1));
}

.item:global(.has-text) .icon > img:global(.video) {
    width: 75%;
}

.item:global(.has-text) .icon > img:global(.game) {
    width: 82.5%;
}

.item .icon > img {
    flex: none;
    max-height: 100%;
    max-width: 100%;
    margin-top: auto;
    margin-bottom: auto;
}

.item:global(.has-text) .icon > img {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0;
}

.item .text {
    flex: none;
    color: #fff;
    height: 40%;
    font-weight: bold;
    text-align: center;
    font-size: 0.525rem;    
    max-width: 100%;
    display: block;
}

.item .note {
    color: #fff;
    position: absolute;
    top: 42%;
    right: 15%;
    font-size: 0.525rem;    
    font-weight: bold;
}