.krpano-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
}

.krpano-wrapper:global {
    animation: fadeIn 2s;
}