.static-ui-container {
    position: absolute;
    height: 10rem;
    display: flex;
    align-items: flex-end;

    z-index: 99999;
}

.static-ui-container:global(.top) {
    top: 2rem;
    bottom: auto;
}

.static-ui-container:global(.bottom) {
    top: auto;
    bottom: 4rem;
}

.static-ui-container:global(.left) {
    left: 3rem;
}

.static-ui-container:global(.right) {
    right: 3rem;
}

.intro-button {
    padding: 1rem;
    position: absolute;
    opacity: 0;
    left: 0;
    top: 0;
    background-color: #ccc;
}

.idle-button {
    padding: 1rem;
    position: absolute;
    opacity: 0;
    right: 0;
    top: 0;
    background-color: #ccc;
}