.language-selection-circular {
    height: 10rem;
    width: 10rem;
    /* overflow: visible; */
}

.language-selection-label {
    position: absolute;
    top: 100%;
    line-height: 1.25;
    width: 100%;
    left: 0;
    text-align: center;
    padding: 0.5rem 1rem;
    font-weight: bold;
}

.language-selection-stacked {
    margin-left: 2rem;
    position: relative;
    min-width: 8.25rem;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    /* justify-items: center; */
}

.language-selection-stacked > button:not(:first-child) {
    margin-top: 0.25rem;
}