.slideshow-content {
    width: 100%;
    height: 100%;
    position: relative;
}

.slideshow-content :global(.swiper-container) {

    border-radius: 3rem;
    overflow: hidden;
}

.slideshow-content :global(.swiper-slide) {
    min-height: 30vh;
    background-color: rgba(255, 255, 255, 0.1);
}

.slideshow-content img {
    width: 100%;
    height: auto;    
}

.slideshow-indicator {
    position: absolute;
    top: 100%;
    margin-top: 0.25rem;
    font-size: 110%;
    font-weight: bold;
    left: 50%;
    transform: translateX(-50%);
}

.slideshow-indicator .slideshow-indicator-separator {
    padding: 0 0.5rem;
}

.slideshow-control {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 25%;
    width: 7rem;    
    opacity: 1;
    transition: opacity 0.2s;
}

.slideshow-control:global(.disabled) {
    opacity: 0.25;
}

.slideshow-control:global(.pointer-down) {
    transform: translateY(-50%) scale(0.95, 0.95);
}

.slideshow-control:global(.mouse-over) {
    filter: drop-shadow(0 0 0.25rem rgba(255, 255, 255, 0.5));
}

.slideshow-control svg line {
    stroke: white;
    stroke-width: 4px;
    stroke-linecap: round;
}

.slideshow-control:global(.prev) {
    right: 100%;
    padding-right: 1.5rem;
}

.slideshow-control:global(.next) {
    left: 100%;
    padding-left: 1.5rem;
}