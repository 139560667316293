.language-slice text, .language-slice tspan {
    alignment-baseline: middle;
    dominant-baseline: middle;
    text-anchor: middle;
    fill: #fff;
    cursor: pointer;
}

.language-slice text {
    font-size: 1.25rem;
}

.language-slice path {
    fill: rgba(0, 0, 0, 0.4);
    transition: fill 0.2s;
}

.language-slice:global(.active) path {
    fill: rgba(0, 0, 0, 0.7);
}