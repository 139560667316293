.progress {
    position: relative;
    min-width: 320px;
    max-width: 520px;
    padding: 1rem;
    z-index: 2;
    text-align: center;
}

.progress .progress-bar {
    background-color: rgba(255, 255, 255, 0.25);
    width: 100%;
    height: 0.75rem;
    border-radius: 10rem;
    overflow: hidden;
}

.progress .progress-bar-inner {
    height: 100%;
    transition: width 0.2s;
    background-color: #fff;
    min-width: 1px;
}

.progress .progress-text {
    padding-top: 0.5rem;
    color: #fff;
}

.progress .progress-message {
    padding-bottom: 1rem;
    color: #fff;
}