.chapter-container {
    width: 100%;
    height: 100%;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);

    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100000;
}

.chapter-container-close-layer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}