.button {
    outline: none;
    background: none;
    border: none;
    appearance: none;
    padding: 0;
    margin: 0;
}

.button:focus {
    outline: none;
}

.button:not([disabled]):not(.disabled) {
    cursor: pointer;
    pointer-events: all;    
}