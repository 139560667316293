.fade-enter {
    opacity: 0.01;
}

.fade-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
}

.fade-enter-done,
.fade-exit {
    opacity: 1;
}

.fade-exit-active {
    opacity: 0.01;
    transition: opacity 500ms ease-in;
}

.fade-exit-done {
    opacity: 0;
}

.slide-top-enter {
    transform: translateY(-150%);
}

.slide-top-enter-active {
    transform: translateY(0);
    transition: transform 400ms ease-in-out 5000ms;
}

.slide-top-exit {
    transform: translateY(0);
}

.slide-top-exit-active {
    transform: translateY(-150%);
    transition: transform 400ms ease-in-out;
}

.fade-animation-enter {
    display: block;
    opacity: 0.01;
}

.fade-animation-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in;
}


.fade-loading-enter {
    display: block;
    opacity: 0.01;
}

.fade-loading-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in;
}

.fade-loading-enter-done,
.fade-loading-exit {
    opacity: 1;
}

.fade-loading-exit-active {
    opacity: 0.01;
    transition: opacity 1000ms ease-out 500ms;
}

.fade-loading-exit-done {
    display: none;
    opacity: 0;
}


.pano-fade-enter {
    display: block;
    opacity: 0.01;
}

.pano-fade-enter-active {
    opacity: 1;
    transition: opacity 400ms ease-in 100ms;
}

.pano-fade-enter-done,
.pano-fade-exit {
    opacity: 1;
}

.pano-fade-exit-active {
    opacity: 0.01;
    transition: opacity 500ms ease-out 500ms;
}

.pano-fade-exit-done {
    display: none;
    opacity: 0;
}

@keyframes lazyFadeIn {
    0% {
        opacity: 0;
    }

    66% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes rotateCenter {
    0% {
        transform: translate(-50%, -50%) rotate(0deg);
    }

    100% {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}