.language-button span {
    font-size: 0.9rem;
    color: #fff;
    font-weight: bold;
}

.language-button {
    background-color: rgba(0, 0, 0, 0.4);
    transition: background-color 0.2s;
    transform: scale(1, 1);
    padding: 0.15rem 1rem;
    border-radius: 0.3rem;
    
}

.language-button:global(.active) {
    background-color: rgba(0, 0, 0, 0.7);
}

.language-button:global(.pointer-down) {
    transform: scale(0.97, 0.97);
}